import React from "react";
import { Button, Typography, Box, Container } from "@mui/material";
import { auth, provider } from "../../firebase";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../stateProvider";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import LoginIcon from "@mui/icons-material/Login";

function LoginView() {
  const [state, dispatch] = useStateValue();

  const signIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        // const user = result.user;
        // ...
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
          token: token,
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        alert(error, errorCode, errorMessage, email, credential);
      });
  };

  return (
    <Container maxWidth="sm">
      <Box p={4} pt="25%">
        <Typography align="center" variant="h1" paragraph>
          Sales Hub
        </Typography>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          disableElevation
          size="large"
          fullWidth
          onClick={signIn}
          endIcon={<LoginIcon />}
        >
          Sign in
        </Button>
        <Typography align="center">
          You need a Legit.Health account to access
        </Typography>
      </Box>
    </Container>
  );
}

export default LoginView;
