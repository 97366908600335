import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import {
  Typography,
  Button,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemIcon,
  Grid,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PanToolIcon from "@mui/icons-material/PanTool";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SalesProcessKnowledge from "./utils/salesProcess.json";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorIcon from "@mui/icons-material/Error";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

const salesProcess = SalesProcessKnowledge;

function SalesProcessView() {
  return (
    <>
      {salesProcess.map((strategy) => (
        <List
          sx={{
            border: "1px solid",
            borderColor: "primary.main",
            borderRadius: 2,
            mb: 3,
          }}
          dense
          disablePadding
          key={strategy.name}
        >
          <ListSubheader
            sx={{
              backgroundColor: "primary.main",
              color: "common.white",
              py: 1,
              mb: "1px",
            }}
          >
            <Typography variant="h5">
              <b>{strategy.stage}</b>
            </Typography>
            <Typography variant="body2">{strategy.name}</Typography>
          </ListSubheader>
          <ListItem
            divider
            sx={{
              backgroundColor: "primary.main",
              color: "common.white",
            }}
          >
            <ListItemText
              disableTypography
              primary={`${strategy.goals.length} Goal(s)`}
            />
          </ListItem>
          {strategy.goals.map((goal, i) => (
            <ListItem sx={{ pl: 4 }} divider key={i}>
              <ListItemIcon>
                <GolfCourseIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText secondary={goal} />
            </ListItem>
          ))}
          <ListItem
            divider
            sx={{
              backgroundColor: "primary.main",
              color: "common.white",
            }}
          >
            <ListItemText disableTypography primary="Sequence" />
          </ListItem>
          <ListItem divider sx={{ backgroundColor: "primary.light" }}>
            <ListItemText primary="Steps" />
          </ListItem>
          {strategy.sequence.steps ? (
            <>
              {strategy.sequence.steps.map((step, i) => (
                <ListItem sx={{ pl: 4 }} divider key={i}>
                  <ListItemIcon>{i + 1}.</ListItemIcon>
                  <ListItemText secondary={step} />
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem sx={{ pl: 4 }}>
              <ListItemIcon>
                <ErrorIcon color="error" />
              </ListItemIcon>
              <ListItemText secondary="There are no steps!" />
            </ListItem>
          )}
          <ListItem divider sx={{ backgroundColor: "primary.light" }}>
            <ListItemText primary="Channels" />
          </ListItem>
          {strategy.sequence.channels.map((channel, i) => (
            <ListItem sx={{ pl: 4 }} divider key={i}>
              <ListItemIcon>{i + 1}.</ListItemIcon>
              <ListItemText secondary={channel} />
            </ListItem>
          ))}
          <ListItem divider sx={{ backgroundColor: "primary.light" }}>
            <ListItemText primary="Targeted positions" />
          </ListItem>
          {strategy.sequence.targetedPositions.map((targetedPosition, i) => (
            <ListItem sx={{ pl: 4 }} divider key={i}>
              <ListItemIcon>
                <PersonSearchIcon />
              </ListItemIcon>
              <ListItemText secondary={targetedPosition} />
            </ListItem>
          ))}
          <ListItem divider sx={{ backgroundColor: "primary.light" }}>
            <ListItemText primary="Triggers And Actions" />
          </ListItem>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ListItem sx={{ pl: 4 }} divider>
                <ListItemIcon>
                  <AutorenewIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Default re-contact frequency"
                  secondary={`${strategy.sequence.triggersAndActions.defaultReContactFrequencyDays} days`}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItem sx={{ pl: 4 }} divider>
                <ListItemIcon>
                  <PanToolIcon />
                </ListItemIcon>
                <ListItemText
                  primary="How many times you must impact before giving up"
                  secondary={
                    strategy.sequence.triggersAndActions.giveUpafterImpacts
                      ? `${strategy.sequence.triggersAndActions.giveUpafterImpacts} impacts per deal`
                      : "Never give up"
                  }
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItem sx={{ pl: 4 }} divider>
                <ListItemIcon>
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Stage ends with..."
                  secondary={strategy.sequence.triggersAndActions.stageEndsWith}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItem sx={{ pl: 4 }} divider>
                <ListItemIcon>
                  <GroupAddIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Multiple leads simoultaneously?"
                  secondary={
                    strategy.sequence.triggersAndActions
                      .multipleLeadsSimoultaneously
                      ? `Between ${strategy.sequence.triggersAndActions.minimumLeadsSimultaneously} and ${strategy.sequence.triggersAndActions.maximumLeadsSimultaneously}`
                      : "Nope, work on a single front"
                  }
                />
              </ListItem>
            </Grid>
          </Grid>
          <ListItem
            divider
            sx={{
              backgroundColor: "primary.main",
              color: "common.white",
            }}
          >
            <ListItemText disableTypography primary="Narrative strategy" />
          </ListItem>
          <ListItem divider sx={{ backgroundColor: "primary.light" }}>
            <ListItemText primary="Questions" />
          </ListItem>
          <ListItem sx={{ py: 1 }} divider>
            <Button
              variant="outlined"
              startIcon={
                strategy.narrativeStrategy.questions.url ? (
                  <DescriptionIcon />
                ) : (
                  <ErrorIcon />
                )
              }
              color={
                strategy.narrativeStrategy.questions.url ? "primary" : "error"
              }
              href={
                strategy.narrativeStrategy.questions.url
                  ? strategy.narrativeStrategy.questions.url
                  : null
              }
              target="_blank"
            >
              Script:{" "}
              {strategy.narrativeStrategy.questions.documentName
                ? strategy.narrativeStrategy.questions.documentName
                : "There is no document!!"}
            </Button>
          </ListItem>
          <ListItem sx={{ backgroundColor: "primary.light" }} divider>
            <ListItemText primary="Persuasive strategy" />
          </ListItem>
          {strategy.narrativeStrategy.persuasiveStrategy.map(
            (persuasiveStrategy, i) => (
              <ListItem sx={{ pl: 4 }} divider key={i}>
                <ListItemIcon>{i + 1}.</ListItemIcon>
                <ListItemText secondary={persuasiveStrategy} />
              </ListItem>
            )
          )}
          <ListItem sx={{ backgroundColor: "primary.light" }} divider>
            <ListItemText primary="Aesthetic approach" />
          </ListItem>
          {strategy.narrativeStrategy.aestheticApproach.map(
            (aestheticApproach, i) => (
              <ListItem sx={{ pl: 4 }} divider key={i}>
                <ListItemIcon>{i + 1}.</ListItemIcon>
                <ListItemText secondary={aestheticApproach} />
              </ListItem>
            )
          )}
        </List>
      ))}
    </>
  );
}

export default SalesProcessView;
