import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDtKD5-EyMajToMdVIzbsCsNxP79VuI-n8",
  authDomain: "sales-hub-v1.firebaseapp.com",
  projectId: "sales-hub-v1",
  storageBucket: "sales-hub-v1.appspot.com",
  messagingSenderId: "91227093573",
  appId: "1:91227093573:web:1e34da143c295a6946c12c",
  measurementId: "G-4NKF7B9QC6",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const analytics = getAnalytics();
const provider = new GoogleAuthProvider();

export { auth, provider, analytics, firebaseApp };
export default db;
