import React from "react";
import { AppBar, Toolbar, Typography, Avatar, Box } from "@mui/material";
import { useStateValue } from "../../stateProvider";
import useIsMobile from "../../useIsMobile";

function MenuView() {
  const [{ user }] = useStateValue();
  const isMobile = useIsMobile();
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <Box flexGrow={1}>
          <Typography sx={{ color: "common.white", mb: 0 }} variant="h2">
            Sales Hub
          </Typography>
        </Box>
        {user && (
          <Box display="flex" alignItems="center">
            <Avatar src={user.photoURL} />
            {!isMobile && (
              <Typography sx={{ color: "common.white", ml: 1 }} variant="h4">
                {user.displayName}
              </Typography>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default MenuView;
