import React from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";

function FooterView() {
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <Box flexGrow={1} sx={{ display: { xs: "none", md: "flex" } }}>
          <Typography
            sx={{
              color: "common.white",
              mb: 0,
              visibility: { xs: "hidden", md: "visible" },
            }}
          >
            If you want to edit this, reach out to Taig on Slack
          </Typography>
        </Box>
        <Button
          variant="text"
          href="https://app.hubspot.com/"
          color="inherit"
          target="_blank"
        >
          HubSpot
        </Button>
        <Button
          variant="text"
          href="https://app-pre.legit.health/"
          color="inherit"
          target="_blank"
        >
          App-pre
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default FooterView;
