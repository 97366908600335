import {
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  Divider,
} from "@mui/material";
import resourcesList from "components/Resources/utils/resourcesList";

const resources = resourcesList;

function ResourcesView() {
  return (
    <Grid container spacing={2}>
      {resources.map((resource) => (
        <Grid item xs={6} md={4} key={resource.name}>
          <Card variant="outlined">
            <CardActionArea href={resource.url} target="_blank">
              <CardMedia
                sx={{ height: 118, objectPosition: "top" }}
                component="img"
                image={resource.img}
                alt={resource.name}
              />
              <Divider />
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  {resource.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {resource.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default ResourcesView;
