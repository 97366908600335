import React from "react";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Login from "./components/Login";
import ContentWithTabs from "./components/ContentWithTabs";
import Unauthorized from "./components/Unauthorized";
import theme from "./theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useStateValue } from "./stateProvider";

function App() {
  const [{ user }, dispatch] = useStateValue();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!user ? (
        <Login />
      ) : user.email.includes("@legit.health") ||
        user.email.includes("celenpaul@gmail.com") ? (
        <>
          <Menu user={user} />
          <ContentWithTabs />
          <Footer />
        </>
      ) : (
        <Unauthorized />
      )}
    </ThemeProvider>
  );
}

export default App;
