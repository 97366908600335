import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Modal,
  Box,
  Button,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import saltBae from "./utils/images/saltbae.png";

function CopyPastersView() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [ApiData, setApiData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://sheet.best/api/sheets/885ba5e8-ff46-4373-8826-a47644f13b2e`)
      .then((incomingData) => {
        setApiData(incomingData.data);
      });
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            background: "#e5edea",
            borderRadius: 3,
            boxShadow: 24,
            p: 4,
            pb: 0,
          }}
        >
          <Typography
            align="center"
            id="modal-modal-title"
            variant="h2"
            color="primary"
          >
            Copied to clipboard 👌
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            You can now paste it where you want
          </Typography>
          <img
            width="200px"
            src={saltBae}
            alt="saltbae"
            style={{ display: "block", margin: "auto", marginBottom: -7 }}
          />
        </Box>
      </Modal>
      <List dense>
        {ApiData.map((copyPaster, i) => (
          <ListItem divider={i < ApiData.length - 1} key={copyPaster.name}>
            <ListItemText
              primary={
                <Typography noWrap sx={{ maxWidth: "80%" }}>
                  <Typography
                    sx={{ fontWeight: "bold", mr: 0.5 }}
                    display="inline"
                    color="primary"
                  >
                    {copyPaster.Type}
                  </Typography>
                  <Typography display="inline">
                    | {copyPaster.WhatIsThis}
                  </Typography>
                </Typography>
              }
              secondary={
                <Typography variant="body2" sx={{ maxWidth: "70%" }} noWrap>
                  Use when the lead <b>{copyPaster.UseWhenTheLead}</b>
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <CopyToClipboard onCopy={handleClick} text={copyPaster.url}>
                <Button startIcon={<ContentCopyIcon />} variant="outlined">
                  Copy
                </Button>
              </CopyToClipboard>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default CopyPastersView;
