import React from "react";
import {
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import Faqs from "./utils/faqs.json";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import stackOverflowImage from "./utils/images/stackOverflow.jpg";

const faqs = Faqs;

function FaqView() {
  return (
    <>
      <Card variant="outlined" sx={{ borderColor: "primary.main", mb: 2 }}>
        <CardActionArea
          href="https://stackoverflow.com/c/legit/questions"
          target="_blank"
          sx={{ display: "flex" }}
        >
          <CardContent sc={{ flex: "1 0 auto" }}>
            <Typography gutterBottom variant="h2" component="div">
              StackOverflow
            </Typography>
            <Typography variant="body2" color="text.secondary">
              You can make new questions or find answers that are not here yet.
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ height: 80, objectPosition: "center" }}
            component="img"
            image={stackOverflowImage}
            alt="stackOverflow"
          />
        </CardActionArea>
      </Card>
      <List>
        {faqs.map((faq, i) => (
          <ListItemButton
            component="a"
            href={faq.url}
            target="_blank"
            divider={i < faqs.length - 1}
            key={faq.question}
          >
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: "bold" }}>
                  {faq.question}
                </Typography>
              }
              secondary={
                <Typography sx={{ maxWidth: "80%" }} noWrap>
                  {faq.answer}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
}

export default FaqView;
