import React from "react";
import { Typography, Box, Container } from "@mui/material";
// import { useStateValue } from "../../stateProvider";

function UnauthorizedView() {
  // const [state, dispatch] = useStateValue();

  return (
    <Container maxWidth="md">
      <Box p={4} pt="25%">
        <Typography align="center" variant="h1" paragraph>
          That's not a Legit.Health account
        </Typography>
        <Typography align="center">
          You need a Legit.Health account to access
        </Typography>
      </Box>
    </Container>
  );
}

export default UnauthorizedView;
