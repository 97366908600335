import legalAndPrivacy from "components/Resources/utils/images/legalAndPrivacy.png";
import coreMessages from "components/Resources/utils/images/coreMessages.png";
import brandGuidelines from "components/Resources/utils/images/brandGuidelines.png";
import deepLinkManual from "components/Resources/utils/images/deepLinkManual.png";
import demoAccounts from "components/Resources/utils/images/demoAccounts.png";
import useCases from "components/Resources/utils/images/useCases.png";
import trelloBoard from "components/Resources/utils/images/trelloBoard.jpg";
import academy from "components/Resources/utils/images/academy.png";
import stackOverflow from "components/Resources/utils/images/stackOverflow.jpg";

const resourcesList = [
  {
    name: "Use cases: Insurance company",
    description: "Insert cool images in your emails to insurance companies",
    url: "https://docs.google.com/presentation/d/1in6xe6VerB79t4kof6eR-sfQ9_XWFrAD1XtssaCWJEY/edit?usp=sharing",
    img: useCases,
  },
  {
    name: "Use cases: For profit Care provider",
    description:
      "Insert cool images in your emails to for profit care providers companies",
    url: "https://docs.google.com/presentation/d/1aVvnNVwJul8uQtWch9mqEkoV07CBQc8epIAENHtYCsA/edit?usp=sharing",
    img: useCases,
  },
  {
    name: "Demo accounts",
    description:
      "This document contains the credential of fake users that work in app-pre.legit.health",
    url: "https://docs.google.com/spreadsheets/d/1eBiqcQWJO4wjzrTAeQsxaLrpMZyl730ppNeT5MJUKjA/edit?usp=sharing",
    img: demoAccounts,
  },
  {
    name: "Stack Overflow",
    description: "Ask and get answers from the Legit.Health team",
    url: "https://stackoverflow.com/c/legit/questions",
    img: stackOverflow,
  },
  {
    name: "Deep Link API manual",
    description: "Legit.Health Deep Link API integration manual for customers",
    url: "https://deep-link-doc.legit.health/",
    img: deepLinkManual,
  },
  {
    name: "Trello boards",
    description: "This link will take you to your business development boards",
    url: "https://trello.com/your/cards/businessdevelopment49",
    img: trelloBoard,
  },
  {
    name: "Legal and privacy assesment",
    description: "Information for legal and privacy assessment",
    url: "https://docs.google.com/presentation/d/1HKjgxMH1NgmQDz48UhUWwVfWh9qsdw90Mj5sENN0dAs/edit?usp=sharing",
    img: legalAndPrivacy,
  },
  {
    name: "Brand guidelines",
    description:
      "In this document you will find information about the colors, fonts and use of images",
    url: "https://docs.google.com/presentation/d/12gf7xhI1zeSgiui_O4NQGeh_5ti8kEet6nAYpl8fzvQ/edit?usp=sharing",
    img: brandGuidelines,
  },
  {
    name: "Core messages",
    description:
      "In this document you will find information about the colors, fonts and use of images",
    url: "https://docs.google.com/spreadsheets/d/1TcUFLxNVnfl7StplkNbKvV17YGeC8H_P84PdOHxX5ds/edit?usp=sharing",
    img: coreMessages,
  },
];

export default resourcesList;
