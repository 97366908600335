import * as React from "react";
import { Tabs, Tab, Grid, Box } from "@mui/material";
import SalesProcess from "../SalesProcess";
import Resources from "../Resources";
import CopyPasters from "../CopyPasters";
import Reports from "../Reports";
import Faq from "../Faq";
import useIsMobile from "../../useIsMobile";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TableChartIcon from "@mui/icons-material/TableChart";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 1, md: 3 } }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const isMobile = useIsMobile();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container margin="auto" alignItems="stretch">
      <Grid item xs={12} md={3} sx={{ backgroundColor: "#f5f5f5" }}>
        <Tabs
          orientation={isMobile ? "horizontal" : "vertical"}
          value={value}
          onChange={handleChange}
          aria-label="tabs"
        >
          <Tab
            icon={<AccountTreeIcon />}
            iconPosition="start"
            label="Sales process"
            {...a11yProps(0)}
          />
          <Tab
            label="Resources"
            icon={<BackupTableIcon />}
            iconPosition="start"
            {...a11yProps(1)}
          />
          <Tab
            label="Questions and answers"
            icon={<QuestionAnswerIcon />}
            iconPosition="start"
            {...a11yProps(2)}
          />
          <Tab
            label="Reports"
            icon={<TableChartIcon />}
            iconPosition="start"
            {...a11yProps(3)}
          />
          <Tab
            label="Copy/Pasters"
            icon={<ContentCopyIcon />}
            iconPosition="start"
            {...a11yProps(4)}
          />
        </Tabs>
      </Grid>
      <Grid item xs={12} md={9} sx={{ px: { xs: 0, md: 2 } }}>
        <TabPanel value={value} index={0}>
          <SalesProcess />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Resources />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Faq />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Reports />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CopyPasters />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
