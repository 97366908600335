import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

function useWindowResize(callback: () => void): void {
  useEffect(() => {
    function handleResize() {
      callback();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [callback]);
}

export default function useIsMobile(): boolean {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia(`(max-width: ${theme.breakpoints.values.md}px)`).matches
  );
  useWindowResize(() =>
    setIsMobile(
      window.matchMedia(`(max-width: ${theme.breakpoints.values.md}px)`).matches
    )
  );
  return isMobile;
}
