import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import axios from "axios";
import LinkIcon from "@mui/icons-material/Link";
import dataStudioImage from "./utils/images/dataStudioImage.png";
import LinkOffIcon from "@mui/icons-material/LinkOff";

const sheetBest =
  "https://sheet.best/api/sheets/635aaee3-efe9-4339-9d96-82954279c19e";

function ReportsView() {
  const [ApiData, setApiData] = useState([]);

  useEffect(() => {
    axios.get(sheetBest).then((incomingData) => {
      setApiData(incomingData.data);
    });
  }, []);

  return (
    <Box px={1} pt={2}>
      <Card variant="outlined" sx={{ borderColor: "primary.main", mb: 2 }}>
        <CardActionArea
          href="https://datastudio.google.com/"
          target="_blank"
          sx={{ display: "flex" }}
        >
          <CardContent sc={{ flex: "1 0 auto" }}>
            <Typography gutterBottom variant="h1">
              Reports
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Here you'll find the DataStudio reports.
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ height: 80, objectPosition: "center" }}
            component="img"
            image={dataStudioImage}
            alt="stackOverflow"
          />
        </CardActionArea>
      </Card>
      <List>
        {ApiData.map((report, i) => (
          <ListItemButton
            component="a"
            href={report.Url}
            target="_blank"
            divider={i < ApiData.length - 1}
            key={report.Name}
          >
            <ListItemIcon>
              {report.Url ? (
                <LinkIcon fontSize="large" color="primary" />
              ) : (
                <LinkOffIcon fontSize="large" color="error" />
              )}
            </ListItemIcon>
            <ListItemText
              disableTypography
              // primary={
              //   <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              //     DataStudio Report
              //   </Typography>
              // }
              secondary={
                <Typography sx={{ maxWidth: "80%" }} noWrap>
                  {report.Name}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}

export default ReportsView;
